import { FC, useMemo, useCallback, useState, MouseEvent, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.min.css';
import {
  IServerSideDatasource,
  GridReadyEvent,
  ColDef,
  PaginationChangedEvent,
  ValueFormatterParams,
  KeyCreatorParams,
  GetContextMenuItems,
  SortChangedEvent,
  FilterChangedEvent,
  FilterModel,
  GridApi,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import Paper from '@mui/material/Paper';
import { useMutation } from '@tanstack/react-query';
import { getOrdersList } from '../http/orders';
import {
  ClientType,
  CommercialPlan,
  CouponStatus,
  OrderDto,
  OrderStatus,
  QuotationType,
} from 'ventas-digitales-v2-common';
import locale, { localizeDateTime } from '../utils/locale';
import UUIDRenderer from './CellRenderers/UUIDRenderer';
import OrderStatusRenderer from './CellRenderers/OrderStatusRenderer';
import ActionsRenderer from './CellRenderers/ActionsRenderer';
import {
  DEFAULT_COL,
  NUMERIC_FILTER_PARAMS,
  PAGE_SIZE_SELECTOR,
  TEXT_FILTER_PARAMS,
  dateFilterParams,
  parseFilters,
} from '../utils/agGrid';
import { useRouter } from 'next/navigation';
import OrderStatusFilterRenderer from './CellRenderers/OrderStatusFilterRenderer';
import { Box, Button, Typography } from '@mui/material';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

const INITIAL_STATUS_FILTER: FilterModel = {
  values: [
    OrderStatus.Signed,
    OrderStatus.Completed,
    OrderStatus.PendingGIS,
    OrderStatus.PendingGIS2,
    OrderStatus.RejectedGIS,
    OrderStatus.ClosedGIS,
    OrderStatus.PendingContractor,
    OrderStatus.RejectedContractor,
    OrderStatus.PendingCertificate,
    OrderStatus.PendingValidation,
    OrderStatus.RejectedValidation,
    OrderStatus.PendingCreation,
    OrderStatus.Created,
    OrderStatus.ReadyToCreate,
    OrderStatus.CreationError,
    OrderStatus.Failed,
  ],
  filterType: 'set',
};

const ALL_STATUS_FILTER: FilterModel = {
  ...INITIAL_STATUS_FILTER,
  values: [
    ...INITIAL_STATUS_FILTER.values,
    OrderStatus.Closed,
    OrderStatus.Pending,
    OrderStatus.InProgress,
    OrderStatus.Deassigned,
  ],
};

const DEFAULT_COLUMNS: ColDef<OrderDto>[] = [
  {
    field: 'id',
    headerName: 'ID',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    tooltipField: 'id',
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: UUIDRenderer,
    resizable: false,
    pinned: true,
    lockPosition: true,
  },
  {
    field: 'orderId',
    headerName: 'Orden OSF',
    filter: 'agNumberColumnFilter',
    filterParams: NUMERIC_FILTER_PARAMS,
    width: 140,
    minWidth: 140,
    valueGetter({ data }) {
      return data?.orderId ?? '-';
    },
  },
  {
    field: 'consecutive',
    headerName: 'Consecutivo',
    filter: 'agNumberColumnFilter',
    filterParams: NUMERIC_FILTER_PARAMS,
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    resizable: false,
    lockPosition: true,
    valueGetter({ data }) {
      return data?.consecutive ?? '-';
    },
  },
  {
    field: 'status',
    headerName: 'Estado',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressSorting: true,
      debounceMs: 500,
      cellRenderer: OrderStatusFilterRenderer,
      values: [
        /** Action-required statuses */
        {
          id: OrderStatus.RejectedGIS,
          label: locale.orderStatusEnum[OrderStatus.RejectedGIS],
        },
        {
          id: OrderStatus.PendingContractor,
          label: locale.orderStatusEnum[OrderStatus.PendingContractor],
        },
        {
          id: OrderStatus.PendingValidation,
          label: locale.orderStatusEnum[OrderStatus.PendingValidation],
        },
        {
          id: OrderStatus.CreationError,
          label: locale.orderStatusEnum[OrderStatus.CreationError],
        },
        /** Final/error statuses */
        {
          id: OrderStatus.ClosedGIS,
          label: locale.orderStatusEnum[OrderStatus.ClosedGIS],
        },
        {
          id: OrderStatus.RejectedContractor,
          label: locale.orderStatusEnum[OrderStatus.RejectedContractor],
        },
        {
          id: OrderStatus.RejectedValidation,
          label: locale.orderStatusEnum[OrderStatus.RejectedValidation],
        },
        {
          id: OrderStatus.Created,
          label: locale.orderStatusEnum[OrderStatus.Created],
        },
        {
          id: OrderStatus.ReadyToCreate,
          label: locale.orderStatusEnum[OrderStatus.ReadyToCreate],
        },
        {
          id: OrderStatus.Failed,
          label: locale.orderStatusEnum[OrderStatus.Failed],
        },
        /** Transition statuses */
        {
          id: OrderStatus.PendingGIS,
          label: locale.orderStatusEnum[OrderStatus.PendingGIS],
        },
        {
          id: OrderStatus.PendingGIS2,
          label: locale.orderStatusEnum[OrderStatus.PendingGIS2],
        },
        {
          id: OrderStatus.PendingCertificate,
          label: locale.orderStatusEnum[OrderStatus.PendingCertificate],
        },
        {
          id: OrderStatus.PendingCreation,
          label: locale.orderStatusEnum[OrderStatus.PendingCreation],
        },
        /** Mobile statuses */
        {
          id: OrderStatus.Pending,
          label: locale.orderStatusEnum[OrderStatus.Pending],
        },
        {
          id: OrderStatus.InProgress,
          label: locale.orderStatusEnum[OrderStatus.InProgress],
        },
        {
          id: OrderStatus.Signed,
          label: locale.orderStatusEnum[OrderStatus.Signed],
        },
        {
          id: OrderStatus.Completed,
          label: locale.orderStatusEnum[OrderStatus.Completed],
        },
        {
          id: OrderStatus.Deassigned,
          label: locale.orderStatusEnum[OrderStatus.Deassigned],
        },
        {
          id: OrderStatus.Closed,
          label: locale.orderStatusEnum[OrderStatus.Closed],
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data ? (locale.orderStatusEnum[data.status] ?? data.status) : '-';
    },
    cellRenderer: OrderStatusRenderer,
  },
  {
    colId: 'type',
    headerName: 'Tipo de Venta',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressMiniFilter: true,
      suppressSorting: true,
      debounceMs: 500,
      values: [
        {
          id: QuotationType.Residential,
          label: locale.quotationTypeEnum[QuotationType.Residential],
        },
        {
          id: QuotationType.SpecialResidential,
          label: locale.quotationTypeEnum[QuotationType.SpecialResidential],
        },
        {
          id: QuotationType.Commercial,
          label: locale.quotationTypeEnum[QuotationType.Commercial],
        },
        {
          id: QuotationType.SpecialCommercial,
          label: locale.quotationTypeEnum[QuotationType.SpecialCommercial],
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 180,
    minWidth: 180,
    valueGetter({ data }) {
      const quotation = data?.residentialQuotation || data?.commercialQuotation;

      if (!quotation) {
        return '-';
      }

      return locale.quotationTypeEnum[quotation.type];
    },
  },
  {
    colId: 'commercialPlan',
    headerName: 'Plan Comercial',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressMiniFilter: true,
      suppressSorting: true,
      showTooltips: true,
      debounceMs: 500,
      values: [
        {
          id: CommercialPlan.ResidentialFullPackage,
          label: `${CommercialPlan.ResidentialFullPackage} - ${locale.commercialPlanEnum[CommercialPlan.ResidentialFullPackage]}`,
        },
        {
          id: CommercialPlan.ResidentialCertifiedConnectionFee,
          label: `${CommercialPlan.ResidentialCertifiedConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.ResidentialCertifiedConnectionFee]}`,
        },
        {
          id: CommercialPlan.ResidentialInternalConnectionFee,
          label: `${CommercialPlan.ResidentialInternalConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.ResidentialInternalConnectionFee]}`,
        },
        {
          id: CommercialPlan.ResidentialConnectionFee,
          label: `${CommercialPlan.ResidentialConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.ResidentialConnectionFee]}`,
        },
        {
          id: CommercialPlan.SpecialResidentialFullPackage,
          label: `${CommercialPlan.SpecialResidentialFullPackage} - ${locale.commercialPlanEnum[CommercialPlan.SpecialResidentialFullPackage]}`,
        },
        {
          id: CommercialPlan.SpecialResidentialCertifiedConnectionFee,
          label: `${CommercialPlan.SpecialResidentialCertifiedConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.SpecialResidentialCertifiedConnectionFee]}`,
        },
        {
          id: CommercialPlan.SpecialResidentialConnectionFee,
          label: `${CommercialPlan.SpecialResidentialConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.SpecialResidentialConnectionFee]}`,
        },
        {
          id: CommercialPlan.CommercialFullPackage,
          label: `${CommercialPlan.CommercialFullPackage} - ${locale.commercialPlanEnum[CommercialPlan.CommercialFullPackage]}`,
        },
        {
          id: CommercialPlan.CommercialCertifiedConnectionFee,
          label: `${CommercialPlan.CommercialCertifiedConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.CommercialCertifiedConnectionFee]}`,
        },
        {
          id: CommercialPlan.CommercialConnectionFee,
          label: `${CommercialPlan.CommercialConnectionFee} - ${locale.commercialPlanEnum[CommercialPlan.CommercialConnectionFee]}`,
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 200,
    minWidth: 200,
    valueGetter({ data }) {
      const quotation = data?.residentialQuotation || data?.commercialQuotation;

      if (!quotation) {
        return '-';
      }

      return `${quotation.commercialPlan} - ${locale.commercialPlanEnum[quotation.commercialPlan]}`;
    },
  },
  {
    colId: 'financed',
    sortable: false,
    headerName: 'Forma de Pago',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressMiniFilter: true,
      suppressSorting: true,
      values: [
        {
          id: true,
          label: 'Financiada',
        },
        {
          id: false,
          label: 'No Financiada',
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    valueGetter({ data }) {
      const quotation = data?.residentialQuotation || data?.commercialQuotation;

      if (!quotation) {
        return '-';
      }

      return quotation.financed ? 'Financiada' : 'No Financiada';
    },
  },
  {
    colId: 'subsidized',
    sortable: false,
    headerName: 'Subsidiada',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressMiniFilter: true,
      suppressSorting: true,
      values: [
        {
          id: true,
          label: 'Subsidada',
        },
        {
          id: false,
          label: 'No Subsidiada',
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 140,
    minWidth: 140,
    maxWidth: 140,
    valueGetter({ data }) {
      if (!data?.residentialQuotation) {
        return '-';
      }

      const { subsidies } = data.residentialQuotation;

      return subsidies.length > 0 ? 'Subsidiada' : 'No Subsidiada';
    },
  },
  {
    colId: 'deal',
    headerName: 'Convenio',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 200,
    minWidth: 200,
    valueGetter({ data }) {
      if (!data?.residentialQuotation) {
        return '-';
      }

      const { subsidies } = data.residentialQuotation;

      return subsidies.length > 0 ? subsidies[0].deal : '-';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de Inicio',
    sort: 'desc',
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams(),
    width: 200,
    minWidth: 200,
    valueGetter({ data }) {
      return data ? localizeDateTime(data.createdAt) : '-';
    },
  },
  {
    field: 'osfCreationDate',
    headerName: 'Fecha de Creación en OSF',
    sort: 'desc',
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams(),
    width: 220,
    minWidth: 220,
    valueGetter({ data }) {
      return data ? localizeDateTime(data.osfCreationDate) : '-';
    },
  },
  {
    field: 'completedAt',
    headerName: 'Fecha de Finalización',
    sort: 'desc',
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams(),
    width: 220,
    minWidth: 220,
    valueGetter({ data }) {
      return data ? localizeDateTime(data.completedAt) : '-';
    },
  },
  {
    field: 'contractId',
    headerName: 'Contrato',
    filter: 'agNumberColumnFilter',
    filterParams: NUMERIC_FILTER_PARAMS,
    width: 160,
    minWidth: 160,
    valueGetter({ data }) {
      return data?.contractId ?? '-';
    },
  },
  {
    colId: 'clientType',
    sortable: false,
    headerName: 'Tipo de Cliente',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressMiniFilter: true,
      suppressSorting: true,
      showTooltips: true,
      debounceMs: 500,
      values: [
        {
          id: ClientType.NaturalPerson,
          label: locale.clientTypeEnum[ClientType.NaturalPerson],
        },
        {
          id: ClientType.LegalPerson,
          label: locale.clientTypeEnum[ClientType.LegalPerson],
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 160,
    minWidth: 160,
    valueGetter({ data }) {
      if (!data?.client) {
        return '-';
      }

      return locale.clientTypeEnum[data.client.clientType];
    },
  },
  {
    colId: 'client',
    headerName: 'Cliente',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 200,
    minWidth: 200,
    valueGetter({ data }) {
      if (!data?.client) {
        return '-';
      }

      const { clientType, fullName, businessName } = data.client;

      return clientType === 1 ? `${businessName} - ${fullName}` : fullName;
    },
  },
  {
    colId: 'identification',
    headerName: 'Identificación Cliente',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 170,
    minWidth: 170,
    valueGetter({ data }) {
      if (!data?.client) {
        return '-';
      }

      const { clientType, identification, businessIdentification } =
        data.client;

      return clientType === 1 ? businessIdentification : identification;
    },
  },
  {
    colId: 'cellphoneNumber',
    headerName: 'Teléfono del Cliente',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 160,
    minWidth: 160,
    valueGetter({ data }) {
      if (!data?.client) {
        return '-';
      }

      return data.client.cellphoneNumber;
    },
  },
  {
    colId: 'couponNumber',
    headerName: 'Cupón',
    filter: 'agNumberColumnFilter',
    filterParams: NUMERIC_FILTER_PARAMS,
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data?.coupon?.couponNumber ?? '-';
    },
  },
  {
    colId: 'couponStatus',
    headerName: 'Estado Cupón',
    filter: 'agSetColumnFilter',
    filterParams: {
      suppressSorting: true,
      values: [
        {
          id: CouponStatus.Generated,
          label: locale.couponStatusEnum[CouponStatus.Generated],
        },
        {
          id: CouponStatus.Sent,
          label: locale.couponStatusEnum[CouponStatus.Sent],
        },
        {
          id: CouponStatus.Paid,
          label: locale.couponStatusEnum[CouponStatus.Paid],
        },
      ],
      keyCreator({ value }: KeyCreatorParams) {
        return value.id;
      },
      valueFormatter({ value }: ValueFormatterParams) {
        return value.label;
      },
    },
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      if (!data?.coupon?.status) {
        return '-';
      }

      return locale.couponStatusEnum[data.coupon.status];
    },
  },
  {
    colId: 'paymentDate',
    headerName: 'Fecha de Pago Cupón',
    sort: 'desc',
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParams(),
    width: 220,
    minWidth: 220,
    valueGetter({ data }) {
      if (!data?.coupon?.paymentDate) {
        return '-';
      }

      return localizeDateTime(data.coupon.paymentDate);
    },
  },
  {
    colId: 'operatingUnit',
    headerName: 'Unidad Operativa',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 200,
    minWidth: 200,
    valueGetter({ data }) {
      return data?.technical?.customParameters?.operatingUnit ?? '-';
    },
  },
  {
    colId: 'technical',
    headerName: 'Técnico',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data?.technical ? (data.technical.name ?? '-') : '-';
    },
  },
  {
    colId: 'department',
    headerName: 'Departamento',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data?.gisAddress?.department ?? '-';
    },
  },
  {
    colId: 'locality',
    headerName: 'Localidad',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data?.gisAddress?.locality ?? '-';
    },
  },
  {
    colId: 'sector',
    headerName: 'Sector',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data?.gisAddress?.sector ?? '-';
    },
  },
  {
    colId: 'fullAddress',
    headerName: 'Dirección',
    filter: 'agTextColumnFilter',
    filterParams: TEXT_FILTER_PARAMS,
    width: 150,
    minWidth: 150,
    valueGetter({ data }) {
      return data?.gisAddress?.fullAddress ?? '-';
    },
  },
];

interface OrdersTableProps {
  operatingUnitsLabel: string | null;
}

const OrdersTable: FC<OrdersTableProps> = ({ operatingUnitsLabel }) => {
  const router = useRouter();

  const { mutateAsync } = useMutation({ mutationFn: getOrdersList });

  const gridApiRef = useRef<GridApi | null>(null);

  const onOpenItem = useCallback(
    (e: MouseEvent<HTMLButtonElement>, order: OrderDto) => {
      const route = `/orders/${order.id}`;

      if (e.ctrlKey) {
        window.open(route, '_blank');
      } else {
        router.push(route);
      }
    },
    [router]
  );

  const columns = useMemo<ColDef<OrderDto>[]>(
    () => [
      {
        colId: 'actions',
        width: 70,
        minWidth: 70,
        maxWidth: 70,
        cellRenderer: ActionsRenderer,
        resizable: false,
        pinned: true,
        lockPosition: true,
        sortable: false,
        cellRendererParams: { onClick: onOpenItem },
        contextMenuItems: [],
      },
      ...DEFAULT_COLUMNS,
    ],
    [onOpenItem]
  );

  const datasource = useMemo<IServerSideDatasource>(
    () => ({
      async getRows({ success, request }) {
        const {
          startRow = 0,
          endRow = 20,
          sortModel: [sortModel],
          filterModel,
        } = request;
        const limit = endRow - startRow;
        const page = Math.floor(startRow / limit) + 1;

        const { colId, sort } = sortModel || {};
        const orders = await mutateAsync({
          limit,
          page,
          sort: colId ? `${colId}:${sort}` : '',
          filters: JSON.stringify(parseFilters(filterModel)),
        });

        success({
          rowData: orders,
        });
      },
    }),
    [mutateAsync]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      gridApiRef.current = event.api;

      const currentPage = localStorage.getItem('PAGE');
      if (currentPage) {
        event.api.setGridOption('onFirstDataRendered', (e) => {
          e.api.paginationGoToPage(Number(currentPage));
        });
      }

      const savedFilters = localStorage.getItem('FILTERS');
      if (savedFilters) {
        const parsedSavedFilters = JSON.parse(savedFilters);
        if (!parsedSavedFilters.status) {
          event.api.setFilterModel({
            ...parsedSavedFilters,
            status: INITIAL_STATUS_FILTER,
          });
        } else {
          event.api.setFilterModel(parsedSavedFilters);
        }
      } else {
        event.api.setFilterModel({
          status: INITIAL_STATUS_FILTER,
        });
      }

      const savedSort = localStorage.getItem('SORT');
      if (savedSort) {
        event.api.applyColumnState({
          state: JSON.parse(savedSort),
          applyOrder: true,
        });
      }

      event.api.setGridOption('serverSideDatasource', datasource);
    },
    [datasource]
  );

  const [pageSize, setPageSize] = useState(() => {
    const currentPageSize = localStorage.getItem('PAGE_SIZE');
    if (currentPageSize) {
      return Number(currentPageSize);
    }

    return 20;
  });

  const onPaginationChanged = useCallback((event: PaginationChangedEvent) => {
    if (event.newPageSize) {
      setPageSize(event.api.paginationGetPageSize());
      localStorage.setItem(
        'PAGE_SIZE',
        event.api.paginationGetPageSize().toString()
      );
    }

    if (event.newPage) {
      localStorage.setItem(
        'PAGE',
        event.api.paginationGetCurrentPage().toString()
      );
    }
  }, []);

  const getContextMenuItems = useCallback<GetContextMenuItems<OrderDto>>(
    ({ node }) => {
      if (node?.data?.id) {
        return [
          'copy',
          'export',
          'autoSizeAll',
          'separator',
          {
            name: 'Abrir en una nueva pestaña',
            action() {
              window.open(`/orders/${node?.data?.id}`, '_blank');
            },
          },
        ];
      }

      return ['export', 'autoSizeAll'];
    },
    []
  );

  const onSortChanged = useCallback((e: SortChangedEvent) => {
    localStorage.setItem('SORT', JSON.stringify(e.api.getColumnState()));
  }, []);

  const onFilterChanged = useCallback((e: FilterChangedEvent) => {
    const filters = e.api.getFilterModel();
    if (!filters?.status) {
      localStorage.setItem(
        'FILTERS',
        JSON.stringify({
          ...filters,
          status: ALL_STATUS_FILTER,
        })
      );
    } else {
      localStorage.setItem('FILTERS', JSON.stringify(e.api.getFilterModel()));
    }
  }, []);

  const onClearFilters = useCallback(() => {
    const gridApi = gridApiRef.current;
    if (gridApi) {
      gridApi.setFilterModel({
        status: INITIAL_STATUS_FILTER,
      });
      localStorage.setItem('FILTERS', JSON.stringify(gridApi.getFilterModel()));
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" fontSize={15} fontWeight="bold">
          listado de ventas{operatingUnitsLabel}
        </Typography>
        <Button
          variant="outlined"
          color="info"
          onClick={onClearFilters}
          size="small"
          startIcon={<CleaningServicesIcon fontSize="small" />}
        >
          <span style={{ padding: 0 }}>Limpiar filtros</span>
        </Button>
      </Box>
      <Paper
        className="ag-theme-quartz"
        sx={{
          fontFamily: 'Roboto',
          height: 'calc(100vh - 250px)',
          minHeight: 500,
          mt: 1,
          overflow: 'clip',
          borderRadius: 2,
        }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          columnDefs={columns}
          rowModelType="serverSide"
          pagination
          paginationPageSize={pageSize}
          cacheBlockSize={pageSize}
          paginationPageSizeSelector={PAGE_SIZE_SELECTOR}
          onPaginationChanged={onPaginationChanged}
          animateRows
          tooltipShowDelay={200}
          defaultColDef={DEFAULT_COL}
          localeText={AG_GRID_LOCALE_ES}
          getContextMenuItems={getContextMenuItems}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
        />
      </Paper>
    </>
  );
};

export default OrdersTable;
